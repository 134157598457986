import React, {useState,useEffect} from 'react'
import { IconButton } from '@material-ui/core';
import { Breadcrumb, Tab,Form, Row, Col, Nav, Button,InputGroup,Badge } from 'react-bootstrap';
import { toast } from 'react-toastify';
import BulkStatusUpdateConfirmModal from './BulkStatusUpdateConfirmModal'
import BulkStatusCancelModal from './BulkStatusCancelModal'
import ProcessCancelModal from './ProcessCancelModal'
import BulkHoldModal from './BulkHoldModal';
import { validateIsAdminUser,validateIsDistributorUser, validateIsSystemsUser } from '../UserCredentials'
import ExportToExcel from './export/ExportToExcel';

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

const OrdersListForm = (props) => {

  let userRoutes=sessionStorage.getItem("allowedRoutes")?JSON.parse(sessionStorage.getItem("allowedRoutes")):[];

  console.log(userRoutes);
  let allowedRoutesSet = new Set();

  for(let i=0;i<userRoutes.length;i++){
    let route=`/${userRoutes[i]}`;
    if(!allowedRoutesSet.has(route)){
      allowedRoutesSet.add(route);
    }
  }
  console.log(allowedRoutesSet);

  let userCity = sessionStorage.getItem("userCity") || "";

  const [ordersListStartDate, setOrdersListStartDate] = useState(formatDate(new Date())+'T00:00')
  const [ordersListEndDate, setOrdersListEndDate] = useState(formatDate(new Date())+'T23:59')
  const [orderListStatus, setOrderListStatus] = useState(`Ordered`)
  const [orderType, setOrderType] = useState(`SuperZop`)
  const [getCity, setCity] = useState("All")
  const [variety, setVariety] = useState(false)
  const [specificOrder, setSpecificOrder] = useState(false)
  const [updateBulkStatusConfirm,setUpdateBulkStatusConfirm] = useState(false)
  const [bulkCancelPopupShow, setBulkCancelPopupShow] = useState(false)
  const [getProcessCancelPopupShow, setProcessCancelPopupShow] = useState(false)
  const [getBulkHoldOrdersPopupShow, setBulkHoldOrdersPopupShow] = useState(false)
  // initialState = {
  //   orderliststartdate: formatDate(new Date())+'T00:00',
  //   orderlistenddate: formatDate(new Date())+'T23:59',
  //   orderliststatus:'Ordered',
  //   ordertype:'All'
  // }

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  const submitForm = () => {
    props.handleSubmit({ordersListStartDate,ordersListEndDate,orderListStatus,orderType,variety,specificOrder,getCity})
  }

  const bulkOrdrPrint = () => {
    if(sessionStorage.getItem("allCheckBoxSelected")=="true" || Object.keys(JSON.parse(sessionStorage.getItem(`bulkPrintConfirm`))).length!==0)
    props.handleBulkOrdersPrintSubmit({ordersListStartDate,ordersListEndDate,orderListStatus,orderType,variety})
    else
    return toast("At least one Order should be selected", { type: toast.TYPE.ERROR })
  }

  const updateBulkProcessed = async (finalStatus) =>{
    await props.bulkStatusProcessed(true,orderListStatus,finalStatus,ordersListStartDate, orderType)
    setUpdateBulkStatusConfirm(false);
    // window.location.reload();
  }

  const cutoffOrder = () => {
    props.updateCheckedForCutoffOrder()
  }

  const syncOrders = () => {
    props.syncOrdersNewTable();
  }

  const updateBulkCancelled = (orderList) => {
    props.bulkOrdersCancellation(orderList);
  }

  const updateProcessCancelled = (orderList) => {
    props.processCancelledSuperkreditOrders(orderList);
  }

  const updateBulkHold = (orderList) => {
    props.bulkHoldOrders(orderList);
  }

  const bulkCancelOrders = () => {
    setBulkCancelPopupShow(true);
  }

  const processCancelledOrders = () => {
    setProcessCancelPopupShow(true);
  }

  const bulkHoldOrders = () => {
    setBulkHoldOrdersPopupShow(true);
  }

  const updateStatusCheck = () =>{

    let bulkOrderssChecks=document.getElementsByClassName('bulkOrdersCheck');
    let count = 0
    for(let i=0;i<bulkOrderssChecks.length;i++){
      if(bulkOrderssChecks[i].checked)
        count=count+1;
    }

    if(count==0 && !sessionStorage.getItem("allCheckBoxSelected"))
    return toast("At least one Order should be selected", { type: toast.TYPE.ERROR })
    else if(count>0 )
    setUpdateBulkStatusConfirm(true)
  }

  const cutOffOrderCheck = () =>{

    let bulkOrderssChecks=document.getElementsByClassName('bulkOrdersCheck');
    let count = 0
    for(let i=0;i<bulkOrderssChecks.length;i++){
      if(bulkOrderssChecks[i].checked)
        count=count+1;
    }
    if(count!==1)
    return toast("Only one Order should be selected", { type: toast.TYPE.ERROR })
    else
    cutoffOrder()

  }



  return (
      <form className="form-horizontal">
        <div className="row" style={{width: "50%",marginLeft: "auto", marginRight: "auto", marginTop:10}}>
          <div className="col">
            <label htmlFor="orderliststartdate" className="control-label">StartDate</label>
            <input type="datetime-local" className="form-control" name="orderliststartdate" id="orderliststartdate" value={ordersListStartDate} onChange={(e)=>{setOrdersListStartDate(e.target.value)}} />
          </div>
          <div className="col">
            <label htmlFor="orderlistenddate" className="control-label">EndDate</label>
            <input type="datetime-local" className="form-control" name="orderlistenddate" id="orderlistenddate" value={ordersListEndDate} onChange={(e)=>{setOrdersListEndDate(e.target.value)}} />
          </div>
        </div>
        <div className="row" style={{width: "50%",marginLeft: "auto", marginRight: "auto", marginTop:10}}>
          <div className="col">
            <label htmlFor="orderliststatus">Status</label>
            <select name="orderliststatus" className="form-control" id="orderliststatus" onChange={(e)=>{setOrderListStatus(e.target.value)}}>
              <option value="All">All</option>
              <option value="Ordered" selected>Ordered</option>
              <option value="Processed">Processed</option>
              <option value="Shipped">Shipped</option>
              <option value="Returned">Returned</option>
              <option value="Cancelled">Cancelled</option>
              <option value="Store Cancelled">Store Cancelled</option>
              <option value="Delivered">Delivered</option>
              <option value="Pending/Hold">Pending/Hold/Processed</option>
            </select>
          </div>
          {!validateIsDistributorUser(JSON.parse(sessionStorage.getItem(`user`))[0].email) && <div className="col">
            <label htmlFor="ordertype">OrderType</label>
            <select name="ordertype" className="form-control" id="ordertype" onChange={(e)=>{setOrderType(e.target.value)}}>
              <option value="All">All</option>
              <option value="SuperZop" selected>SuperZop</option>
              <option value="ShortOrders">Short Orders</option>
              <option value="ReturnOrders">GatePass / ReturnOrders</option>
              <option value="SuperCoinOrders">SuperCoin Orders</option>
              <option value="OtherOrders">Other Orders</option>
              <option value="DCSaleOrders">DC Sale Orders</option>
              {/* <option value="Distributor">Distributor Orders</option> */}
              <option value="Khetika">Khetika Orders</option>
              {/* <option value="Fresh">Fresh Orders</option> */}
              {/* <option value="CreditOrders">Credit Orders</option> */}
              <option value="CreditUsers">Credit Users</option>
              {/* <option value="ModernTrade">Modern Trade</option> */}
              <option value="ModernTradeOnline">Modern Trade Online</option>
              {/* <option value="ConsumerPack">Consumer Pack</option> */}
            </select>
          </div>}
          {(userCity=="Bhiwandi" || userCity=="") && !validateIsDistributorUser(JSON.parse(sessionStorage.getItem(`user`))[0].email) && <div className="col">
            <label htmlFor="citylist">City</label>
            <select name="citylist" className="form-control" id="citylist" onChange={(e)=>{setCity(e.target.value)}}>
              <option value="All">All</option>
              <option value="Mumbai">Mumbai</option>
              <option value="Nashik">Nashik</option>
              <option value="Surat">Surat</option>
              {/* <option value="Alibag">Alibag</option> */}
              <option value="Pune">Pune</option>
            </select>
          </div>}
        </div>

        <div className="row" style={{width: "50%",marginLeft: "auto", marginRight: "auto", marginTop:10}}>
          <div className="col">
            <Form.Check type="checkbox" label="SMALL PACKS ONLY" onChange={(e)=>{setVariety(e.target.checked);console.log("Small Pack status:: "+e.target.checked)}} />
          </div>
          <div className="col">
            <Form.Check type="checkbox" label="NEW STORE ORDERS ONLY" onChange={(e)=>{setSpecificOrder(e.target.checked)}} />
          </div>
        </div>

        <div className="row" style={{width: "50%",marginLeft: "auto", marginRight: "auto", marginTop:10}}>
          <div className="col">
            <input type="button" style={{marginLeft: "auto", marginRight: "auto"}} className="btn btn-primary" value="View Orders" onClick={submitForm} />
          </div>
        </div>
        {validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email)) && <Button  variant="warning" style={{margin:10}} onClick={bulkOrdrPrint} >Bulk Print </Button>}
        {validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))?(<Button  variant="dark" style={{margin:10}} onClick={()=>updateStatusCheck()} >Update Status</Button>):null}
        {validateIsSystemsUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))?(<Button  variant="dark" style={{margin:10}} onClick={()=>bulkCancelOrders()} >Bulk Cancel Orders</Button>):null}
        {validateIsSystemsUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))?(<Button  variant="dark" style={{margin:10}} onClick={()=>processCancelledOrders()} >Process Cancelled Orders</Button>):null}
        {validateIsSystemsUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))?(<Button  variant="dark" style={{margin:10}} onClick={()=>bulkHoldOrders()} >Bulk Hold</Button>):null}
        {validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))?<Button  variant="warning" style={{margin:10}} onClick={()=>cutOffOrderCheck()} >CutOff Order</Button>:null}
        {validateIsAdminUser((JSON.parse(sessionStorage.getItem(`user`))[0].email))?<Button variant="success" style={{margin:10}} onClick={()=>{syncOrders();}} >Sync Orders</Button>:null}
        <a type="button" style={{margin:10}} href="/returnedorders" className="btn btn-primary">Returned Orders</a>

       { validateIsAdminUser(
        JSON.parse(sessionStorage.getItem(`user`))[0].email
      )  ? ( <ExportToExcel
          sheet1={props.ordersList}
          filename={`Orders List`}
          sheet1Headers={props.ordersListExportHeaders}
          sheet1Name={'Orders'}
          styles={{margin:10}}
        />) : null}

        {updateBulkStatusConfirm && <BulkStatusUpdateConfirmModal
            show={updateBulkStatusConfirm}
            onHide={() => setUpdateBulkStatusConfirm(false)}
            updateDetails={props.ordersList}
            updateBulkProcessed={updateBulkProcessed}
        />}

        {bulkCancelPopupShow && <BulkStatusCancelModal
            show={bulkCancelPopupShow}
            onHide={() => setBulkCancelPopupShow(false)}
            updateBulkCancelled={updateBulkCancelled}
        />}

        {getProcessCancelPopupShow && <ProcessCancelModal
            show={getProcessCancelPopupShow}
            onHide={() => setProcessCancelPopupShow(false)}
            updateProcessCancelled={updateProcessCancelled}
        />}

        {getBulkHoldOrdersPopupShow && <BulkHoldModal
            show={getBulkHoldOrdersPopupShow}
            onHide={() => setBulkHoldOrdersPopupShow(false)}
            updateBulkHold={updateBulkHold}
        />}

      </form>
    );
}

export default OrdersListForm
