import React, { useEffect,useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import OrderListTable from './orderstable'
import OrderListForm from './ordersform'
import OrdersListSearchForm from './Orderslistsearch'
import BulkOrdersPrint from './bulkOrdersPrint'
import NavBar from '../Navigation bar/Navbar'
import database from '../../Configs/firebase'
import Backdrop from '../backdrop/backdrop'
import { toast } from 'react-toastify';
import { InputGroupAddon, Input, InputGroupText, CardHeader, CardTitle, CardText } from 'reactstrap';
import {Tab,Row,Col,Nav,Form,Dropdown,ProgressBar,Tabs,Image,InputGroup,Button,DropdownButton,Breadcrumb,Table,ButtonGroup,Container, Accordion,Card, Navbar} from 'react-bootstrap'
import fire from '../../Configs/firebase'
import {support_portal_url, service_url} from '../../Configs/mysqlconfig';
import { validateIsAdminUser, validateIsDistributorUser } from '../UserCredentials'

function formatDate(date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

function arr_diff (a1, a2) {
    var a = [], diff = [];
    for (var i = 0; i < a1.length; i++) {
        a[a1[i]] = true;
    }

    for (var i = 0; i < a2.length; i++) {
        if (a[a2[i]]) {
            delete a[a2[i]];
        } else {
            a[a2[i]] = true;
        }
    }

    for (var k in a) {
        diff.push(k);
    }

    return diff;
}

Number.prototype.padLeft = function(base,chr){
    var  len = (String(base || 10).length - String(this).length)+1;
    return len > 0? new Array(len).join(chr || '0')+this : this;
}

const OrderList =()=> {

  const [ordersList, setOrdersList] = useState([])
  const [searchOrdersList, setSearchOrdersList] = useState([])
  const [backDrop, setBackDrop] = useState(true)
  const [originalListing, setOriginalListing] = useState([])
  const [totalOrderValue, setTotalOrderValue] = useState("")
  const [totalOrderCount, setTotalOrderCount] = useState("")
  const [totalWeight, setTotalWeight] = useState("")
  let [orderCounter, setOrderCounter] = useState(0)
  let [orderCountFiltered, setOrderCountFiltered] = useState(0)
  const [weightCountFiltered, setWeightCountFiltered] = useState(0)
  const [totalOrderValSearched, setTotalOrderValSearched] = useState(0)
  const [totalOrderValOnCheck, setTotalOrderValOnCheck] = useState(0)
  const [totalOrderCountOnCheck, setTotalOrderCountOnCheck] = useState(0)
  const [totalOrderWtOnCheck, setTotalOrderWtOnCheck] = useState(0)
  const [isNewRetailerOrder, setIsNewRetailerOrder] = useState(false)
  const [getCutoffOrderObj, setCutoffOrderObj] = useState({})

  const ordersListExportHeaders = React.useMemo(
    () => [
      {
        label: "Order Number",
        value: "order_number",
      },
      {
        label: "Retailer Id",
        value: "retailer_id",
      },
      {
        label: "Order Value",
        value: "net_order_amt",
      },
      {
        label: "Shop Name",
        value: "shop_name",
      },
      {
        label: "Weight (Kg)",
        value: "weight",
      },
      {
        label: "Order Date",
        value: "order_date_conv",
      },
      {
        label: "Status",
        value: "status",
      },
    ],
    []
  );

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });


  useEffect(() => {
    let curdate=formatDate(new Date());
    let userCity = sessionStorage.getItem("userCity") || "";
    let order_type = "SuperZop";
    if(validateIsDistributorUser(JSON.parse(sessionStorage.getItem(`user`))[0].email)) {
      order_type = "Distributor";
    }

    let apiurlstr=`${support_portal_url}/api/orders?startDate=${curdate}T00%3A00&endDate=${curdate}T23%3A59&status=Ordered&ordertype=${order_type}&city=All&token=${sessionStorage.getItem("apiToken")}`

    fetch(apiurlstr)
    .then((response) => {
      return response.json();
    })
    .then((result) => {

      if(result['success']==1) {
        setTotalOrderValue(result['total_order_amount'])
        setTotalOrderCount(result['total_orders'])
        setTotalWeight(result['total_weight'])
        setOriginalListing(result['data'])
        setOrdersList(result['data'])
        setSearchOrdersList(result['data'])
      } else {
        setOrdersList([])
        setSearchOrdersList([])
        let errMessage=result['message']?result['message']:"";
        alert(errMessage);
        if(result['status']==401 || result['status']==403){
          sessionStorage.clear();
          window.location.href="/"
        }
      }
      setBackDrop(false)
    })
    .catch((error) => {
      setOrdersList([])
      setSearchOrdersList([])
    });
  }, [])

  useEffect(() => {
    let apiurlstr=`${support_portal_url}/api/cutofforder?token=${sessionStorage.getItem("apiToken")}`
    fetch(apiurlstr)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      if(result['success']==1) {
        setCutoffOrderObj(result['data'])
      }
    })
    .catch((error) => {
      setCutoffOrderObj({})
    });
  },[])

  const handleBulkOrdersPrintSubmit = (orderlistformdata) => {
    let ordersListStartDate=formatDate(orderlistformdata['ordersListStartDate']);
    let ordersListEndDate=formatDate(orderlistformdata['ordersListEndDate']);

    let bulkOrderssChecks=document.getElementsByClassName('bulkOrdersCheck');
    let bulkOrderNumbersList=[];
    let bulkOrderNumbersString="(";

    let orderCount=0;
    for(let i=0;i<bulkOrderssChecks.length;i++){
      if(bulkOrderssChecks[i].checked){
        bulkOrderNumbersString=bulkOrderNumbersString+"'"+ordersList[i].order_number+"',";
        bulkOrderNumbersList.push(ordersList[i].order_number);
        orderCount=orderCount+1;
      }
    }
    bulkOrderNumbersString = bulkOrderNumbersString.slice(0, -1);
    bulkOrderNumbersString+=")";
    console.log(bulkOrderNumbersString);
    console.log(bulkOrderNumbersList);
    BulkOrdersPrint(bulkOrderNumbersList);

    if(ordersListStartDate!=ordersListEndDate && orderCount>=100){
      toast("StartDate and EndDate should be same for Bulk Printing.", { type: toast.TYPE.ERROR });
    }else{
      sessionStorage.setItem("bulkOrderIdToPrint",bulkOrderNumbersString)
      if(orderlistformdata['orderType']=="Khetika") {
        window.open(`/bulkorderdetails?startDate=${ordersListStartDate}&endDate=${ordersListEndDate}&orderType=Khetika`, "_blank");
      } else {
        window.open(`/bulkorderdetails?startDate=${ordersListStartDate}&endDate=${ordersListEndDate}`, "_blank");
      }
    }
  }

  const handleSubmit = (orderlistformdata) => {
    console.log(orderlistformdata);
    setBackDrop(true)
    // {orderliststartdate: "2020-07-16T16:05", orderlistenddate: "2020-07-16T16:06", orderliststatus: "Delivered"}
    let orderlistformstartdate=orderlistformdata['ordersListStartDate']
    let orderlistformenddate=orderlistformdata['ordersListEndDate']
    let orderlistformstatus=orderlistformdata['orderListStatus']
    let ordertype=orderlistformdata['orderType']
    let variety=orderlistformdata['variety']
    let city=orderlistformdata['getCity']
    let specificOrder=orderlistformdata['specificOrder']

    let apiurlstr=`${support_portal_url}/api/orders?token=${sessionStorage.getItem("apiToken")}&`;
    if(orderlistformstartdate){
      apiurlstr+='startDate='
      let orderlistformstartdt=orderlistformstartdate.split('T');
      apiurlstr+=orderlistformstartdt[0];
      apiurlstr+='T';
      let orderlistformstartdatetime=orderlistformstartdt[1].split(':');
      apiurlstr+=orderlistformstartdatetime[0];
      apiurlstr+='%3A'
      apiurlstr+=orderlistformstartdatetime[1];
    }

    if(orderlistformenddate){
      apiurlstr+='&endDate='
      let orderlistformenddt=orderlistformenddate.split('T');
      apiurlstr+=orderlistformenddt[0];
      apiurlstr+='T';
      let orderlistformenddatetime=orderlistformenddt[1].split(':');
      apiurlstr+=orderlistformenddatetime[0];
      apiurlstr+='%3A'
      apiurlstr+=orderlistformenddatetime[1];
    }

    if(orderlistformstatus){
      apiurlstr+='&status=';
      apiurlstr+=orderlistformstatus;
    }else{
      apiurlstr+='&status=';
      apiurlstr+='Ordered';
    }

    if(ordertype){
      apiurlstr+='&ordertype=';
      if(validateIsDistributorUser(JSON.parse(sessionStorage.getItem(`user`))[0].email)) {
        apiurlstr+=`Distributor`;
      }else{
        apiurlstr+=ordertype;
      }
    }else{
      apiurlstr+='&ordertype=';
      apiurlstr+='All';
    }

    if(variety) {
      apiurlstr+='&variety=SMALL PACK'
    }

    let userCity = sessionStorage.getItem("userCity") || "";
    if(city && (userCity=="Bhiwandi" || userCity=="")) {
      apiurlstr+=`&city=${city}`
    }else{
      apiurlstr+=`&city=All`
    }

    console.log(apiurlstr);

    // let url = 'http://support.superzop.com/api/orders?startDate=2020-07-13T00%3A00&endDate=2020-07-13T23%3A59&status=Processed'

    fetch(apiurlstr)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if(result['success']==1){
          setIsNewRetailerOrder(specificOrder);
          if(result['total_order_amount'] && (!specificOrder)) {

            setTotalOrderValue(result['total_order_amount'])
            setTotalOrderCount(result['total_orders'])
            setTotalWeight(result['total_weight'])
          }
          if(specificOrder){
            let orderSpecificArray = []
            let netOrderAmt = 0
            let netOrderWt = 0
            let netOrderCount = 0

            result['data'].forEach(items=>{
              if((items.order_number.split('/')[1]===`1000001` || items.order_number.split('/')[1]===`1000002`) && !(items.order_number.includes("R") || items.order_number.includes("S"))){
              orderSpecificArray.push(items)
              netOrderAmt += Number(items.net_order_amt)
              netOrderWt += Number(items.weight)
              netOrderCount += 1
              }
            })
            console.log(orderSpecificArray)
            setBackDrop(false)
            setOriginalListing(orderSpecificArray)
            setOrdersList(orderSpecificArray)
            setSearchOrdersList(orderSpecificArray)
            setTotalOrderValue(netOrderAmt)
            setTotalOrderCount(netOrderCount)
            setTotalWeight(netOrderWt)
            return
          }
          console.log(result['data'])
          setBackDrop(false)
          setOriginalListing(result['data'])
          setOrdersList(result['data'])
          setSearchOrdersList(result['data'])
        }else{
          let errMessage=result['message']?result['message']:"";
          alert(errMessage);
          setBackDrop(false)
          setOrdersList([])
          setSearchOrdersList([])
          if(result['status']==401 || result['status']==403){
            sessionStorage.clear();
            window.location.href="/"
          }
        }
      })
      .catch((error) => {
        alert('Error:', error);
        setBackDrop(false)
        setOrdersList([])
        setSearchOrdersList([])

      });
    // character  : it will give the data entered in form
    //this.setState({characters: [character]})                           do this if you just want new data
    // this.setState({orderslist: [...this.state.characters, character]})
  }


  const filterSearchedOrders = (countNos) =>{
    setOrderCountFiltered(countNos)
  }

  const filterTotalWt = (totalWt) =>{
    setWeightCountFiltered(totalWt)
  }

  const totalOrderValCalc = (orderTotal) =>{
      setTotalOrderValSearched(orderTotal)
  }

  const updateCheckedForCutoffOrder = () => {
    setBackDrop(true)
    let bulkOrderssChecks=document.getElementsByClassName('bulkOrdersCheck');
    let cutoffOrderNumber='';
    let cutoffOrderDate='';
    let cutoffcount=0;
    for(let i=0;i<bulkOrderssChecks.length;i++){
      if(bulkOrderssChecks[i].checked){
        cutoffOrderNumber=ordersList[i].order_number;
        cutoffOrderDate=ordersList[i].order_date_conv;
        cutoffcount=cutoffcount+1;
      }
    }

    if(cutoffcount==0){
      setBackDrop(false)
      toast("Please select order number.", { type: toast.TYPE.ERROR });
    }else if(cutoffcount>1){
      setBackDrop(false)
      toast("Please select only one order number.", { type: toast.TYPE.ERROR });
    }else{
      let details = {
        'ordernumber': cutoffOrderNumber,
        'orderdate': cutoffOrderDate,
        'delivery_date': Date.now()
      };
      console.log(details);

      fetch(`${support_portal_url}/api/updatecutofforder`, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
              'x-access-token': sessionStorage.getItem("apiToken")
          },
          body: JSON.stringify(details)
      })
      .then(response => { return response.json(); })
      .then(data => {
        if(data['success']==1){
          setBackDrop(false)
          return toast("Updated cutoff order", { type: toast.TYPE.SUCCESS });
        }else{
          setBackDrop(false)
          let errMessage=data['message']?data['message']:"";
          toast(errMessage, { type: toast.TYPE.ERROR });
          if(data['status']==401 || data['status']==403){
            sessionStorage.clear();
            window.location.href="/"
          }
        }
      })
      .catch((error) => {
          setBackDrop(false)
          return toast("Couldn't update cutoff order : "+error, { type: toast.TYPE.ERROR });
      });
    }
  }

  const getRetailerIdFromOrderNumber = (orderNo) => {
    if(orderNo.includes("-")) {
      return orderNo.split("-")[0];
    } else if(orderNo.includes("SC")) {
      let retailerStr = orderNo.split("/")[0];
      return retailerStr.substr(retailerStr.length - 5);
    } else {
      return orderNo.split("/")[0];
    }
  }

 const handleBulkStatusProcessed = async (bulkUpdateConfirm,bulkOrdersCurrentStatus,bulkOrdersFinalStatus,ordrStartDate, orderType) =>{
   console.log('bulkOrdersCurrentStatus');
   console.log(bulkOrdersCurrentStatus);
   console.log(bulkOrdersFinalStatus);

   setBackDrop(true)
    if(bulkUpdateConfirm){
      let bulkOrdersList=[];
      let bulkOrderssChecks=document.getElementsByClassName('bulkOrdersCheck');
      let bulkRetailersList = [];
      let bulkFirebaseOrdersStr='';
      let bulkOrderNumbersString="(";
      let zeptoOrdersListDetails = [];
      let values=[];
      // let insertQuery = '';
      for(let i=0;i<bulkOrderssChecks.length;i++){
        if(bulkOrderssChecks[i].checked){
          bulkOrderNumbersString=bulkOrderNumbersString+"'"+ordersList[i].order_number+"',";
          let fbordernumber=ordersList[i].order_number;
          let fbordernumberlist=fbordernumber.split('/');
          let finalfbordernumber=fbordernumberlist[0]+'-'+fbordernumberlist[1];
          bulkFirebaseOrdersStr=bulkFirebaseOrdersStr+finalfbordernumber+'#';
          bulkOrdersList.push(ordersList[i].order_number);

          let retailerID = getRetailerIdFromOrderNumber(ordersList[i].order_number);
          bulkRetailersList.push(retailerID);
          if(orderType == 'ModernTradeOnline' && bulkOrdersFinalStatus == 'Processed' && bulkOrdersCurrentStatus == 'Ordered') {
            //insertQuery = 'INSERT INTO orders (retailer_id, order_date, exp_delivery_date, order_number, item_id, ord_qty, ord_wgt) VALUES ';
            const { retailer_id, order_date_conv: order_date, exp_delivery_date, order_number, item_ids_list, quantity_list, po_number_list, weight_list, items_list, dealer_price_list,gst_charge_list} = ordersList[i];
            const itemIds = item_ids_list.split(',');
            const quantities = quantity_list.split(',');
            const weights = weight_list.split(',');
            const itemNames = items_list.split(',');
            const poNumbers = po_number_list ? po_number_list.split(',') : [];
            const dealer_prices = dealer_price_list ? dealer_price_list.split(',') : '';
            const gst_charges = gst_charge_list ? gst_charge_list.split(',') : ''; 

            itemIds.forEach((item_id, index) => {
              const ord_qty = parseInt(quantities[index], 10);
              const ord_wgt = parseFloat(weights[index]);
              const item_name = itemNames[index];
              const po_number = poNumbers[index] || null;
              const dealer_price = dealer_prices[index] || null;
              // const gst_charge = (gst_charges[index]/parseFloat(weights[index])) || null;
              let gst_charge = null;

              if (gst_charges[index] && weights[index]) {
                  const weight = parseFloat(weights[index]);
                  if (weight !== 0) {
                      gst_charge = (gst_charges[index] / weight).toFixed(2);
                  }
              }
              // values.push(`(${retailer_id}, '${order_date}', NULL, '${order_number}', '${item_id}', ${ord_qty}, ${ord_wgt})`);
              // zeptoOrdersListDetails.push(values);
              zeptoOrdersListDetails.push({
                retailer_id,
                order_date,
                exp_delivery_date,
                order_number,
                item_id,
                item_name,
                ord_qty,
                ord_wgt,
                po_number,
                dealer_price,
                gst_charge
              });
            });

          }
        }
      }
      if(orderType == 'ModernTradeOnline' && bulkOrdersFinalStatus == 'Processed' && bulkOrdersCurrentStatus == 'Ordered') {
        let baseURL =`${service_url}/api/superzop/admin/online-orders-tracking`;
        const response = await fetch(baseURL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': sessionStorage.getItem("apiToken")
        },
          body: JSON.stringify(zeptoOrdersListDetails)
        })

        const finalData = await response.json();
        if(finalData.success == 1) {
          console.log("Data updated for zepto orders in Online_Orders_Tracking table ")
        } else {
          console.log("Error while updating data");
          toast(`Couldn't update Orders to Online_Orders_Tracking in MySQL `, { type: toast.TYPE.ERROR });
        }

      }

      bulkOrderNumbersString = bulkOrderNumbersString.slice(0, -1);
      bulkOrderNumbersString+=")";
      bulkFirebaseOrdersStr=bulkFirebaseOrdersStr.slice(0,-1);
      console.log(bulkOrderNumbersString);
      console.log(bulkFirebaseOrdersStr);
      let d = new Date();
      let currdformat = [d.getFullYear(),
              (d.getMonth()+1).padLeft(),
               d.getDate().padLeft()].join('/') +' ' +
              [d.getHours().padLeft(),
               d.getMinutes().padLeft()].join(':');

     console.log(bulkOrdersList);

      let details = {
        'orders': bulkOrderNumbersString,
        'firebaseOrders':bulkFirebaseOrdersStr,
        'currentstatus':bulkOrdersCurrentStatus,
        'status': bulkOrdersFinalStatus,
        'currentdateTime':currdformat,
        'ordrStartDate':formatDate(new Date(ordrStartDate)),
        'bulkOrdersList':bulkOrdersList,
        'updated_by':JSON.parse(sessionStorage.getItem("user"))[0].email,
        'updated_from':"Disha-OrderList"
      };

      if(bulkOrdersCurrentStatus=='Pending/Hold' && bulkOrdersFinalStatus=='Hold') {
        details['currentstatus'] = 'Pending';
      }

      console.log(JSON.stringify(details));

      if((bulkOrdersFinalStatus=='Processed' && (bulkOrdersCurrentStatus=='Ordered' || bulkOrdersCurrentStatus=='Cancelled'))
       || (bulkOrdersFinalStatus=='Cancelled' && (bulkOrdersCurrentStatus=='Ordered' || bulkOrdersCurrentStatus=='Processed'))
        || (bulkOrdersFinalStatus=='Hold' && (bulkOrdersCurrentStatus=='Ordered' || bulkOrdersCurrentStatus=='Processed' || bulkOrdersCurrentStatus=='Pending/Hold'))){
        //update mysql and firebase
        console.log(bulkFirebaseOrdersStr);
        //update in firebase
        let firebaseOrdersList=bulkFirebaseOrdersStr.split('#');
        console.log('firebaseOrdersList');
        console.log(firebaseOrdersList);
        let ordersnewlist=[];
        let ordersnewele={};
        fire.database().ref("Orders_New").once("value", snapshot => {
          if(snapshot.hasChildren()){
            let ordersnew=snapshot.val();
            let driverOrdersObj={};

            for(let i=0;i<firebaseOrdersList.length;i++){
              let fbordernumber=firebaseOrdersList[i];
              let fbordernumberlst=fbordernumber.split('-');
              let modifyfborderrnumber='';
              if(fbordernumber.includes('A') || fbordernumber.includes('B') || fbordernumber.includes('-C') || fbordernumber.includes('-S') || fbordernumber.includes('R')){
                modifyfborderrnumber=fbordernumberlst[0]+'-'+fbordernumberlst[1]+'/'+fbordernumberlst[2];
              }else{
                modifyfborderrnumber=fbordernumberlst[0]+'/'+fbordernumberlst[1];
              }

              console.log(modifyfborderrnumber);
              Object.keys(ordersnew).map((key,index)=>{
                if(ordersnew[key]['order_number']==modifyfborderrnumber){
                  ordersnewlist.push(fbordernumber);
                  if(ordersnew[key]['status']=='Ordered' && bulkOrdersCurrentStatus=='Ordered' && bulkOrdersFinalStatus=='Processed'){
                    ordersnewele[`/${key}/status`]='Processed';
                    ordersnewele[`/${key}/processed_date`]=currdformat;
                  }else if(ordersnew[key]['status']=='Processed' && bulkOrdersCurrentStatus=='Processed' && bulkOrdersFinalStatus=='Shipped'){
                    ordersnewele[`/${key}/status`]='Shipped';
                  }else if(bulkOrdersFinalStatus=='Cancelled' && (bulkOrdersCurrentStatus=='Ordered' || bulkOrdersCurrentStatus=='Processed')
                   && (ordersnew[key]['status']=='Ordered' || ordersnew[key]['status']=='Processed')) {
                    ordersnewele[`/${key}/status`]='Cancelled';
                  }else if(ordersnew[key]['status']=='Cancelled' && bulkOrdersCurrentStatus=='Cancelled' && bulkOrdersFinalStatus=='Processed'){
                    ordersnewele[`/${key}/status`]='Processed';
                  }else if(bulkOrdersFinalStatus=='Hold' && (bulkOrdersCurrentStatus=='Ordered' || bulkOrdersCurrentStatus=='Processed' || bulkOrdersCurrentStatus=='Pending/Hold')
                   && (ordersnew[key]['status']=='Ordered' || ordersnew[key]['status']=='Processed' || ordersnew[key]['status']=='Pending')) {
                    ordersnewele[`/${key}/status`]='Hold';
                  }else if(bulkOrdersFinalStatus=='Cancelled' && bulkOrdersCurrentStatus=='Pending/Hold' && (ordersnew[key]['status']=='Pending' || ordersnew[key]['status']=='Hold')){
                    ordersnewele[`/${key}/status`]='Cancelled';

                    let fbOrderNumber=ordersnew[key]['order_number'];
                    fbOrderNumber=fbOrderNumber.replace("-","_");
                    let fbOrderItemId=ordersnew[key]['item_id'];

                    let dohkey = fbOrderNumber.split("/")[0]+"-"+fbOrderNumber.split("/")[1]+"-"+fbOrderItemId;
                    let dohele = JSON.parse(JSON.stringify(ordersnew[key]));
                    dohele['status']='Cancelled';
                    driverOrdersObj[dohkey]=dohele;
                  }
                }
              });
            }
            console.log(ordersnewele);
            let orderStartDate=formatDate(new Date(ordrStartDate));
            let currdate=formatDate(new Date());

            if(orderStartDate==currdate){
              if(Object.keys(ordersnewele).length>0){
                //update in orders new
                let orrdersNewRef=fire.database().ref('Orders_New');
                orrdersNewRef.update(ordersnewele,function(err){
                  if (err) {
                    setBackDrop(false)
                    return toast(`Couldn't update Orders to ${bulkOrdersFinalStatus} in MySQL and Firebase : ${err}`, { type: toast.TYPE.ERROR });
                  } else {
                    fetch(`${support_portal_url}/api/updatemysqlbulkorderstatus`, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            'x-access-token': sessionStorage.getItem("apiToken")

                        },
                        body: JSON.stringify(details)
                    })
                    .then(response => { return response.json(); })
                    .then(data => {
                      console.log(data);

                        if(data["success"]==1){
                          if(bulkOrdersFinalStatus=='Cancelled' && bulkOrdersCurrentStatus=='Pending/Hold'){
                            let currDate=new Date();
                            let date=currDate.getDate();
                            let month=currDate.getMonth()+1;
                            let year=currDate.getFullYear();
                            let formattedDate = `${date}-${month}-${year}`;
                            fire.database().ref(`Driver_Orders_History/${year}/${month}/${formattedDate}/9998/`).update(driverOrdersObj,function(upderr){
                              if(upderr){
                                setBackDrop(false)
                                return toast(`Couldn't update Orders to ${bulkOrdersFinalStatus} in MySQL and Firebase : ${upderr}`, { type: toast.TYPE.ERROR });
                              }else{
                                setBackDrop(false)
                                toast(`Updated Orders to ${bulkOrdersFinalStatus} in MySQL and Firebase`, { type: toast.TYPE.SUCCESS });
                                window.location.reload();
                                return;
                              }
                            })
                          }else{
                            setBackDrop(false)
                            toast(`Updated Orders to ${bulkOrdersFinalStatus} in MySQL and Firebase`, { type: toast.TYPE.SUCCESS });
                            window.location.reload();
                            return;
                          }
                        }else{
                          setBackDrop(false)
                          let errorMessage=data["message"]?data["message"]:"";
                          toast(`Couldn't update Orders to ${bulkOrdersFinalStatus} in MySQL : ${errorMessage}`, { type: toast.TYPE.ERROR });

                          if(data['status']==401 || data['status']==403){
                            sessionStorage.clear();
                            window.location.href="/"
                          }else{
                            setTimeout(function(){
                               window.location.reload();
                            }, 3000);
                          }
                        }
                    })
                    .catch((error) => {
                        setBackDrop(false)
                        return toast(`Couldn't update Orders to ${bulkOrdersFinalStatus} in MySQL : ${error}`, { type: toast.TYPE.ERROR });
                    });
                  }
                });
              }else{
                setBackDrop(false)
                return toast(`Couldn't update Orders to ${bulkOrdersFinalStatus} in MySQL and firebase : Orders not found in OrdersNew`, { type: toast.TYPE.ERROR });
              }
            }else{
              if(Object.keys(ordersnewele).length>0){
                //update in orders new
                let orrdersNewRef=fire.database().ref('Orders_New');
                orrdersNewRef.update(ordersnewele);

                if(bulkOrdersFinalStatus=='Cancelled' && bulkOrdersCurrentStatus=='Pending/Hold'){
                  let currDate=new Date();
                  let date=currDate.getDate();
                  let month=currDate.getMonth()+1;
                  let year=currDate.getFullYear();
                  let formattedDate = `${date}-${month}-${year}`;
                  fire.database().ref(`Driver_Orders_History/${year}/${month}/${formattedDate}/9998/`).update(driverOrdersObj,function(upderr){
                    if(upderr){
                      setBackDrop(false)
                      return toast(`Couldn't update Orders to ${bulkOrdersFinalStatus} in MySQL and Firebase : ${upderr}`, { type: toast.TYPE.ERROR });
                    }else{
                      setBackDrop(false)
                      toast(`Updated Orders to ${bulkOrdersFinalStatus} in MySQL and Firebase`, { type: toast.TYPE.SUCCESS });
                      window.location.reload();
                      return;
                    }
                  })
                }
              }

              let orders_set = new Set(ordersnewlist);
              let order_new_list = [...orders_set];
              console.log(order_new_list);
              let ordershistorylist=arr_diff(order_new_list,firebaseOrdersList);
              console.log(ordershistorylist);

              for(let z=0;z<ordershistorylist.length;z++){
                let fborderhistorynumber=ordershistorylist[z];
                let retaileridd=ordershistorylist[z].split('-')[0];
                console.log(retaileridd);
                fire.database().ref(`Orders_History/${retaileridd}/`).once("value", snapshort => {
                  if(snapshort.hasChildren()){
                    let finalordershistory=snapshort.val();
                    // console.log(finalordershistory);
                    Object.keys(finalordershistory).map((fhkey,fhindex)=>{
                      let ordershistoryele={};
                      let historyDriverOrdersObj={};
                      if(fhkey.includes(fborderhistorynumber)){
                        console.log(fhkey);
                        if(finalordershistory[fhkey]['status']=='Ordered' && bulkOrdersCurrentStatus=='Ordered' && bulkOrdersFinalStatus=='Processed'){
                          ordershistoryele[`/${retaileridd}/${fhkey}/status`]='Processed';
                          ordershistoryele[`/${retaileridd}/${fhkey}/processed_date`]=currdformat;
                        }else if(finalordershistory[fhkey]['status']=='Processed' && bulkOrdersCurrentStatus=='Processed' && bulkOrdersFinalStatus=='Shipped'){
                          ordershistoryele[`/${retaileridd}/${fhkey}/status`]='Shipped';
                        }else if(bulkOrdersFinalStatus=='Cancelled' && (bulkOrdersCurrentStatus=='Ordered' || bulkOrdersCurrentStatus=='Processed')
                         && (finalordershistory[fhkey]['status']=='Ordered' || finalordershistory[fhkey]['status']=='Processed')){
                          ordershistoryele[`/${retaileridd}/${fhkey}/status`]='Cancelled';
                        }else if(finalordershistory[fhkey]['status']=='Cancelled' && bulkOrdersCurrentStatus=='Cancelled' && bulkOrdersFinalStatus=='Processed'){
                          ordershistoryele[`/${retaileridd}/${fhkey}/status`]='Processed';
                        }else if(bulkOrdersFinalStatus=='Hold' && (bulkOrdersCurrentStatus=='Ordered' || bulkOrdersCurrentStatus=='Processed' || bulkOrdersCurrentStatus=='Pending/Hold')
                         && (finalordershistory[fhkey]['status']=='Ordered' || finalordershistory[fhkey]['status']=='Processed' || finalordershistory[fhkey]['status']=='Pending')) {
                          ordershistoryele[`/${retaileridd}/${fhkey}/status`]='Hold';
                        }else if(bulkOrdersFinalStatus=='Cancelled' && bulkOrdersCurrentStatus=='Pending/Hold'
                         && (finalordershistory[fhkey]['status']=='Pending' || finalordershistory[fhkey]['status']=='Hold')){
                          ordershistoryele[`/${retaileridd}/${fhkey}/status`]='Cancelled';

                          let dohele = JSON.parse(JSON.stringify(finalordershistory[fhkey]));
                          dohele['status']='Cancelled';
                          historyDriverOrdersObj[fhkey]=dohele;
                        }

                        console.log(ordershistoryele);

                        if(Object.keys(ordershistoryele).length>0){
                          let orrdersHistoryRef=fire.database().ref('Orders_History');
                          orrdersHistoryRef.update(ordershistoryele);

                          if(bulkOrdersFinalStatus=='Cancelled' && bulkOrdersCurrentStatus=='Pending/Hold'){
                            let currDate=new Date();
                            let date=currDate.getDate();
                            let month=currDate.getMonth()+1;
                            let year=currDate.getFullYear();
                            let formattedDate = `${date}-${month}-${year}`;
                            fire.database().ref(`Driver_Orders_History/${year}/${month}/${formattedDate}/9998/`).update(historyDriverOrdersObj);
                          }

                        }
                      }
                    });
                  }
                });
              }

              fetch(`${support_portal_url}/api/updatemysqlbulkorderstatus`, {
                  method: 'PUT',
                  headers: {
                      'Content-Type': 'application/json',
                      'x-access-token': sessionStorage.getItem("apiToken")
                  },
                  body: JSON.stringify(details),
              })
              .then(response => {
                 console.log(response);
                 return response.json();
               })
              .then(data => {
                console.log(data);
                  setBackDrop(false)

                  if(data["success"]==1){
                    toast(`Updated Orders to ${bulkOrdersFinalStatus} in MySQL and Firebase`, { type: toast.TYPE.SUCCESS });
                    window.location.reload();
                    return;
                  }else{
                    let errorMessage=data["message"]?data["message"]:"";
                    return toast(`Couldn't update Orders to ${bulkOrdersFinalStatus} in MySQL : ${errorMessage}`, { type: toast.TYPE.ERROR });

                    if(data['status']==401 || data['status']==403){
                      sessionStorage.clear();
                      window.location.href="/"
                    }else{
                      setTimeout(function(){
                         window.location.reload();
                      }, 3000);
                    }
                  }
              })
              .catch((error) => {
                  setBackDrop(false)
                  return toast(`Couldn't update Orders to ${bulkOrdersFinalStatus} in MySQL : ${error}`, { type: toast.TYPE.ERROR });
              });
            }

          }
        });

      }else if(bulkOrdersFinalStatus=='Cancelled' && bulkOrdersCurrentStatus=='Pending/Hold'){

        let dohcurrDate=new Date();
        let dohdate=dohcurrDate.getDate();
        let dohmonth=dohcurrDate.getMonth()+1;
        let dohyear=dohcurrDate.getFullYear();
        let dohformattedDate = `${dohdate}-${dohmonth}-${dohyear}`;

        console.log(bulkFirebaseOrdersStr.split('#'));
        console.log("bulkRetailersList");
        console.log(bulkRetailersList);
        let apiBody = {
          "retailerList" : bulkRetailersList
        }

        Promise.all([
          fire.database().ref(`Driver_Orders_History/${dohyear}/${dohmonth}/${dohformattedDate}`).once("value"),
          fire.database().ref("Orders_New").once("value"),
          fetch(`${support_portal_url}/api/retailer-phone`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'x-access-token': sessionStorage.getItem("apiToken")
              },
              body: JSON.stringify(apiBody)
          }).then(response => response.json()),
          fire.database().ref("Superzop_Kredit_Internal").once('value')
        ]).then(([dohsnapshot, snapshot, apiData, internalcreditsnap]) => {

          let dohorders=dohsnapshot.val() || {};
          let plannedOrdersSet=new Set();
          let retailerPhoneMap = apiData['data'] || {};
          console.log("retailerPhoneMap");
          console.log(retailerPhoneMap);

          let internalCreditData=internalcreditsnap.val() || {};
          let orderCreditMap = {};

          if(dohsnapshot.val()){
            Object.keys(dohorders).map((rkey,rindex)=>{
              let routeOrders=dohorders[rkey];

              Object.keys(routeOrders).map((dohkey,dohindex)=>{
                let orderNum=routeOrders[dohkey].order_number;
                if(!plannedOrdersSet.has(orderNum)){
                  plannedOrdersSet.add(orderNum);
                }
              })
            })
          }
          console.log(plannedOrdersSet);
          console.log(bulkFirebaseOrdersStr);
          //update in firebase
          let firebaseOrdersList=bulkFirebaseOrdersStr.split('#');
          console.log('firebaseOrdersList');
          console.log(firebaseOrdersList);

          let mysqlOrdersList=[];
          let mysqlOrderSstr="(";
          for(let i=0;i<firebaseOrdersList.length;i++){
            let fbordernumber=firebaseOrdersList[i];
            let fbordernumberlst=fbordernumber.split('-');
            let modifyfborderrnumber='';
            if(fbordernumber.includes('A') || fbordernumber.includes('B') || fbordernumber.includes('-C') || fbordernumber.includes('-S') || fbordernumber.includes('R')){
              modifyfborderrnumber=fbordernumberlst[0]+'-'+fbordernumberlst[1]+'/'+fbordernumberlst[2];
            }else{
              modifyfborderrnumber=fbordernumberlst[0]+'/'+fbordernumberlst[1];
            }

            if(!plannedOrdersSet.has(modifyfborderrnumber)){
              mysqlOrdersList.push(modifyfborderrnumber);
              mysqlOrderSstr+=`'${modifyfborderrnumber}',`
            }
          }

          console.log(mysqlOrdersList);

          mysqlOrderSstr=mysqlOrderSstr.slice(0,-1);
          mysqlOrderSstr+=")";

          details['orders']=mysqlOrderSstr;
          details['bulkOrdersList']=mysqlOrdersList;

          let ordersnewlist=[];
          let ordersnewele={};

            if(snapshot.hasChildren()){
              let ordersnew=snapshot.val();
              let driverOrdersObj={};

              for(let i=0;i<firebaseOrdersList.length;i++){
                let fbordernumber=firebaseOrdersList[i];
                let fbordernumberlst=fbordernumber.split('-');
                let modifyfborderrnumber='';
                if(fbordernumber.includes('A') || fbordernumber.includes('B') || fbordernumber.includes('-C') || fbordernumber.includes('-S') || fbordernumber.includes('R')){
                  modifyfborderrnumber=fbordernumberlst[0]+'-'+fbordernumberlst[1]+'/'+fbordernumberlst[2];
                }else{
                  modifyfborderrnumber=fbordernumberlst[0]+'/'+fbordernumberlst[1];
                }

                console.log(modifyfborderrnumber);
                Object.keys(ordersnew).map((key,index)=>{
                  if(ordersnew[key]['order_number']==modifyfborderrnumber && !plannedOrdersSet.has(modifyfborderrnumber)){
                    ordersnewlist.push(fbordernumber);
                    if(ordersnew[key]['status']=='Ordered' && bulkOrdersCurrentStatus=='Ordered' && bulkOrdersFinalStatus=='Processed'){
                      ordersnewele[`/${key}/status`]='Processed';
                      ordersnewele[`/${key}/processed_date`]=currdformat;
                    }else if(ordersnew[key]['status']=='Processed' && bulkOrdersCurrentStatus=='Processed' && bulkOrdersFinalStatus=='Shipped'){
                      ordersnewele[`/${key}/status`]='Shipped';
                    }else if(bulkOrdersFinalStatus=='Cancelled' && (bulkOrdersCurrentStatus=='Ordered' || bulkOrdersCurrentStatus=='Processed')
                     && (ordersnew[key]['status']=='Ordered' || ordersnew[key]['status']=='Processed')) {
                      ordersnewele[`/${key}/status`]='Cancelled';
                    }else if(ordersnew[key]['status']=='Cancelled' && bulkOrdersCurrentStatus=='Cancelled' && bulkOrdersFinalStatus=='Processed'){
                      ordersnewele[`/${key}/status`]='Processed';
                    }
                  }else if(ordersnew[key]['order_number']==modifyfborderrnumber && plannedOrdersSet.has(modifyfborderrnumber) && bulkOrdersFinalStatus=='Cancelled' && bulkOrdersCurrentStatus=='Pending/Hold' && (ordersnew[key]['status']=='Pending' || ordersnew[key]['status']=='Hold')) {
                    ordersnewele[`/${key}/status`]='Cancelled';

                    let fbOrderNumber=ordersnew[key]['order_number'];
                    fbOrderNumber=fbOrderNumber.replace("-","_");
                    let fbOrderItemId=ordersnew[key]['item_id'];

                    let dohkey = fbOrderNumber.split("/")[0]+"-"+fbOrderNumber.split("/")[1]+"-"+fbOrderItemId;
                    let dohele = JSON.parse(JSON.stringify(ordersnew[key]));
                    dohele['status']='Cancelled';
                    dohele['driver_id']='9998';
                    dohele['vehicle']=9998;
                    dohele['order_date']=formatDate(new Date(dohele['order_date']));
                    let retailerID = dohele['retailer_id'];
                    dohele['phone'] = retailerPhoneMap[retailerID] || "";
                    driverOrdersObj[dohkey]=dohele;

                    let orderCreditAmt = Number(ordersnew[key]['credit_amt']) || 0;
                    if(orderCreditAmt>0) {
                      orderCreditMap[ordersnew[key]['order_number']] = orderCreditAmt;
                    }
                  }
                });
              }
              console.log(ordersnewele);
              let orderStartDate=formatDate(new Date(ordrStartDate));
              let currdate=formatDate(new Date());

              if(orderStartDate==currdate){
                // process superkredit orders
                let kreditObj = {};
                Object.keys(orderCreditMap).map((key,index) => {
                  let kreditRetailer = getRetailerIdFromOrderNumber(key);
                  let creditAmt = orderCreditMap[key];

                  let retailerAvailableCredit=0;
                  if(internalCreditData){
                    if(internalCreditData[kreditRetailer]){
                      if(internalCreditData[kreditRetailer]['approved_details']){
                        retailerAvailableCredit = Number(internalCreditData[kreditRetailer]['approved_details'].available_credit) || 0;
                      }
                    }
                  }

                  let finalRetailerAvailableCredit = retailerAvailableCredit + creditAmt;
                  finalRetailerAvailableCredit = finalRetailerAvailableCredit.toFixed(2);

                  kreditObj[`/${kreditRetailer}/approved_details/available_credit`] = Number(finalRetailerAvailableCredit);
                })

                console.log(kreditObj);

                if(Object.keys(kreditObj).length>0) {
                  fire.database().ref(`Superzop_Kredit_Internal`).update(kreditObj);
                }

                if(Object.keys(ordersnewele).length>0){
                  //update in orders new
                  let orrdersNewRef=fire.database().ref('Orders_New');
                  orrdersNewRef.update(ordersnewele,function(err){
                    if (err) {
                      setBackDrop(false)
                      return toast(`Couldn't update Orders to ${bulkOrdersFinalStatus} in MySQL and Firebase : ${err}`, { type: toast.TYPE.ERROR });
                    } else {
                      if(mysqlOrdersList.length>0){
                        fetch(`${support_portal_url}/api/updatemysqlbulkorderstatus`, {
                            method: 'PUT',
                            headers: {
                                'Content-Type': 'application/json',
                                'x-access-token': sessionStorage.getItem("apiToken")
                            },
                            body: JSON.stringify(details)
                        })
                        .then(response => { return response.json(); })
                        .then(data => {
                          console.log(data);

                            if(data["success"]==1){
                              if(bulkOrdersFinalStatus=='Cancelled' && bulkOrdersCurrentStatus=='Pending/Hold'){
                                let currDate=new Date();
                                let date=currDate.getDate();
                                let month=currDate.getMonth()+1;
                                let year=currDate.getFullYear();
                                let formattedDate = `${date}-${month}-${year}`;
                                fire.database().ref(`Driver_Orders_History/${year}/${month}/${formattedDate}/9998/`).update(driverOrdersObj,function(upderr){
                                  if(upderr){
                                    setBackDrop(false)
                                    return toast(`Couldn't update Orders to ${bulkOrdersFinalStatus} in MySQL and Firebase : ${upderr}`, { type: toast.TYPE.ERROR });
                                  }else{
                                    setBackDrop(false)
                                    toast(`Updated Orders to ${bulkOrdersFinalStatus} in MySQL and Firebase`, { type: toast.TYPE.SUCCESS });

                                    setTimeout(function(){
                                       window.location.reload();
                                    }, 3000);

                                    return;
                                  }
                                })
                              }else{
                                setBackDrop(false)
                                toast(`Updated Orders to ${bulkOrdersFinalStatus} in MySQL and Firebase`, { type: toast.TYPE.SUCCESS });

                                setTimeout(function(){
                                   window.location.reload();
                                }, 3000);

                                return;
                              }
                            }else{
                              setBackDrop(false)
                              let errorMessage=data["message"]?data["message"]:"";
                              toast(`Couldn't update Orders to ${bulkOrdersFinalStatus} in MySQL : ${errorMessage}`, { type: toast.TYPE.ERROR });

                              if(data['status']==401 || data['status']==403){
                                sessionStorage.clear();
                                window.location.href="/"
                              }else{
                                setTimeout(function(){
                                   window.location.reload();
                                }, 3000);
                              }
                            }
                        })
                        .catch((error) => {
                            setBackDrop(false)
                            return toast(`Couldn't update Orders to ${bulkOrdersFinalStatus} in MySQL : ${error}`, { type: toast.TYPE.ERROR });
                        });
                      }else{
                        setBackDrop(false)
                        return toast(`Orders already planned for delivery`, { type: toast.TYPE.ERROR });
                      }

                    }
                  });
                }else{
                  setBackDrop(false)
                  return toast(`Couldn't update Orders to ${bulkOrdersFinalStatus} in MySQL and firebase : Orders not found in OrdersNew`, { type: toast.TYPE.ERROR });
                }
              }else{
                if(Object.keys(ordersnewele).length>0){
                  //update in orders new
                  let orrdersNewRef=fire.database().ref('Orders_New');
                  orrdersNewRef.update(ordersnewele);

                  if(bulkOrdersFinalStatus=='Cancelled' && bulkOrdersCurrentStatus=='Pending/Hold'){
                    let currDate=new Date();
                    let date=currDate.getDate();
                    let month=currDate.getMonth()+1;
                    let year=currDate.getFullYear();
                    let formattedDate = `${date}-${month}-${year}`;
                    fire.database().ref(`Driver_Orders_History/${year}/${month}/${formattedDate}/9998/`).update(driverOrdersObj,function(upderr){
                      if(upderr){
                        setBackDrop(false)
                        toast(`Couldn't update Orders to ${bulkOrdersFinalStatus} in MySQL and Firebase : ${upderr}`, { type: toast.TYPE.ERROR });
                      }else{
                        setBackDrop(false)
                        toast(`Updated Orders to ${bulkOrdersFinalStatus} in MySQL and Firebase`, { type: toast.TYPE.SUCCESS });
                      }
                    })
                  }
                }

                let orders_set = new Set(ordersnewlist);
                let order_new_list = [...orders_set];
                console.log(order_new_list);
                let ordershistorylist=arr_diff(order_new_list,firebaseOrdersList);
                console.log(ordershistorylist);

                for(let z=0;z<ordershistorylist.length;z++){
                  let fborderhistorynumber=ordershistorylist[z];

                  let fborderhistorynumberlst=fborderhistorynumber.split('-');
                  let modifyfborderhistorynumber='';
                  if(fborderhistorynumber.includes('A') || fborderhistorynumber.includes('B') || fborderhistorynumber.includes('-C') || fborderhistorynumber.includes('-S') || fborderhistorynumber.includes('R')){
                    modifyfborderhistorynumber=fborderhistorynumberlst[0]+'-'+fborderhistorynumberlst[1]+'/'+fborderhistorynumberlst[2];
                  }else{
                    modifyfborderhistorynumber=fborderhistorynumberlst[0]+'/'+fborderhistorynumberlst[1];
                  }

                  if(!plannedOrdersSet.has(modifyfborderhistorynumber)){
                    let retaileridd=ordershistorylist[z].split('-')[0];
                    console.log(retaileridd);
                    fire.database().ref(`Orders_History/${retaileridd}/`).once("value", snapshort => {
                      if(snapshort.hasChildren()){
                        let finalordershistory=snapshort.val();
                        // console.log(finalordershistory);
                        Object.keys(finalordershistory).map((fhkey,fhindex)=>{
                          let ordershistoryele={};
                          let historyDriverOrdersObj={};
                          if(fhkey.includes(fborderhistorynumber)){
                            console.log(fhkey);
                            if(finalordershistory[fhkey]['status']=='Ordered' && bulkOrdersCurrentStatus=='Ordered' && bulkOrdersFinalStatus=='Processed'){
                              ordershistoryele[`/${retaileridd}/${fhkey}/status`]='Processed';
                              ordershistoryele[`/${retaileridd}/${fhkey}/processed_date`]=currdformat;
                            }else if(finalordershistory[fhkey]['status']=='Processed' && bulkOrdersCurrentStatus=='Processed' && bulkOrdersFinalStatus=='Shipped'){
                              ordershistoryele[`/${retaileridd}/${fhkey}/status`]='Shipped';
                            }else if(bulkOrdersFinalStatus=='Cancelled' && (bulkOrdersCurrentStatus=='Ordered' || bulkOrdersCurrentStatus=='Processed')
                             && (finalordershistory[fhkey]['status']=='Ordered' || finalordershistory[fhkey]['status']=='Processed')){
                              ordershistoryele[`/${retaileridd}/${fhkey}/status`]='Cancelled';
                            }else if(finalordershistory[fhkey]['status']=='Cancelled' && bulkOrdersCurrentStatus=='Cancelled' && bulkOrdersFinalStatus=='Processed'){
                              ordershistoryele[`/${retaileridd}/${fhkey}/status`]='Processed';
                            }

                            if(Object.keys(ordershistoryele).length>0){
                              let orrdersHistoryRef=fire.database().ref('Orders_History');
                              orrdersHistoryRef.update(ordershistoryele);

                              if(bulkOrdersFinalStatus=='Cancelled' && bulkOrdersCurrentStatus=='Pending/Hold'){
                                let currDate=new Date();
                                let date=currDate.getDate();
                                let month=currDate.getMonth()+1;
                                let year=currDate.getFullYear();
                                let formattedDate = `${date}-${month}-${year}`;
                                fire.database().ref(`Driver_Orders_History/${year}/${month}/${formattedDate}/9998/`).update(historyDriverOrdersObj);
                              }

                            }
                          }
                        });
                      }
                    });
                  } else if(plannedOrdersSet.has(modifyfborderhistorynumber) && bulkOrdersFinalStatus=='Cancelled' && bulkOrdersCurrentStatus=='Pending/Hold') {
                    let retaileridd=ordershistorylist[z].split('-')[0];
                    console.log(retaileridd);
                    fire.database().ref(`Orders_History/${retaileridd}/`).once("value", snapshort => {
                      if(snapshort.hasChildren()){
                        let finalordershistory=snapshort.val();
                        // console.log(finalordershistory);
                        Object.keys(finalordershistory).map((fhkey,fhindex)=>{
                          let ordershistoryele={};
                          let historyDriverOrdersObj={};
                          if(fhkey.includes(fborderhistorynumber)){
                            console.log(fhkey);
                            if(bulkOrdersFinalStatus=='Cancelled' && bulkOrdersCurrentStatus=='Pending/Hold'
                             && (finalordershistory[fhkey]['status']=='Pending' || finalordershistory[fhkey]['status']=='Hold')){
                              ordershistoryele[`/${retaileridd}/${fhkey}/status`]='Cancelled';

                              let dohele = JSON.parse(JSON.stringify(finalordershistory[fhkey]));
                              dohele['status']='Cancelled';
                              dohele['driver_id']='9998';
                              dohele['vehicle']=9998;
                              dohele['order_date']=formatDate(new Date(dohele['order_date']));
                              let retailerID = dohele['retailer_id'];
                              dohele['phone'] = retailerPhoneMap[retailerID] || "";
                              historyDriverOrdersObj[fhkey]=dohele;

                              let orderCreditAmt = Number(finalordershistory[fhkey]['credit_amt']) || 0;
                              if(orderCreditAmt>0) {
                                orderCreditMap[finalordershistory[fhkey]['order_number']] = orderCreditAmt;
                              }
                            }

                            console.log(ordershistoryele);

                            // process superkredit order
                            let kreditObj = {};
                            Object.keys(orderCreditMap).map((key,index) => {
                              let kreditRetailer = getRetailerIdFromOrderNumber(key);
                              let creditAmt = orderCreditMap[key];

                              let retailerAvailableCredit=0;
                              if(internalCreditData){
                                if(internalCreditData[kreditRetailer]){
                                  if(internalCreditData[kreditRetailer]['approved_details']){
                                    retailerAvailableCredit = Number(internalCreditData[kreditRetailer]['approved_details'].available_credit) || 0;
                                  }
                                }
                              }

                              let finalRetailerAvailableCredit = retailerAvailableCredit + creditAmt;
                              finalRetailerAvailableCredit = finalRetailerAvailableCredit.toFixed(2);

                              kreditObj[`/${kreditRetailer}/approved_details/available_credit`] = Number(finalRetailerAvailableCredit);
                            })

                            console.log(kreditObj);

                            if(Object.keys(kreditObj).length>0) {
                              fire.database().ref(`Superzop_Kredit_Internal`).update(kreditObj);
                            }

                            if(Object.keys(ordershistoryele).length>0){
                              let orrdersHistoryRef=fire.database().ref('Orders_History');
                              orrdersHistoryRef.update(ordershistoryele);

                              if(bulkOrdersFinalStatus=='Cancelled' && bulkOrdersCurrentStatus=='Pending/Hold'){
                                let currDate=new Date();
                                let date=currDate.getDate();
                                let month=currDate.getMonth()+1;
                                let year=currDate.getFullYear();
                                let formattedDate = `${date}-${month}-${year}`;
                                fire.database().ref(`Driver_Orders_History/${year}/${month}/${formattedDate}/9998/`).update(historyDriverOrdersObj);
                              }

                            }
                          }
                        });
                      }
                    });
                  }

                }

                if(mysqlOrdersList.length>0){
                  fetch(`${support_portal_url}/api/updatemysqlbulkorderstatus`, {
                      method: 'PUT',
                      headers: {
                          'Content-Type': 'application/json',
                          'x-access-token': sessionStorage.getItem("apiToken")
                      },
                      body: JSON.stringify(details),
                  })
                  .then(response => {
                     console.log(response);
                     return response.json();
                   })
                  .then(data => {
                    console.log(data);
                      setBackDrop(false)

                      if(data["success"]==1){
                        toast(`Updated Orders to ${bulkOrdersFinalStatus} in MySQL and Firebase`, { type: toast.TYPE.SUCCESS });
                        setTimeout(function(){
                           window.location.reload();
                        }, 3000);

                        return;
                      }else{
                        let errorMessage=data["message"]?data["message"]:"";
                        return toast(`Couldn't update Orders to ${bulkOrdersFinalStatus} in MySQL : ${errorMessage}`, { type: toast.TYPE.ERROR });

                        if(data['status']==401 || data['status']==403){
                          sessionStorage.clear();
                          window.location.href="/"
                        }else{
                          setTimeout(function(){
                             window.location.reload();
                          }, 3000);
                        }
                      }
                  })
                  .catch((error) => {
                      setBackDrop(false)
                      return toast(`Couldn't update Orders to ${bulkOrdersFinalStatus} in MySQL : ${error}`, { type: toast.TYPE.ERROR });
                  });
                }else{
                  setBackDrop(false)
                  return toast(`Orders already planned for delivery`, { type: toast.TYPE.ERROR });
                }

              }

            }


        });
      }else{
        setBackDrop(false)
        toast("Please select Ordered,Processed or Cancelled as status.", { type: toast.TYPE.ERROR });
      }
    }
  }

  const bulkHoldOrders = (ordersList) => {
    console.log("bulkHoldOrders");
    console.log(ordersList);

    let d = new Date(),
  currdformat = [d.getFullYear(),
            (d.getMonth()+1).padLeft(),
             d.getDate().padLeft()].join('/') +' ' +
            [d.getHours().padLeft(),
             d.getMinutes().padLeft()].join(':');

   let ordersStr = "(";
   for(let order of ordersList) {
     ordersStr += `'${order}',`
   }

   if(ordersStr.length>1) {
     ordersStr = ordersStr.slice(0,-1);
     ordersStr+= ")";
   }

    let details = {
      'orders': ordersStr,
      'currentstatus':"Ordered",
      'status': "Hold",
      'updated_at':currdformat,
      'updated_by':JSON.parse(sessionStorage.getItem("user"))[0].email,
      'updated_from':"Disha-OrderList",
      'currentdateTime':currdformat,
      'bulkOrdersList':ordersList
    };

    fire.database().ref("Orders_New").once("value").then((snapshot) => {
      if(snapshot.hasChildren()){
        let ordersnew=snapshot.val();

        for(let i=0;i<ordersList.length;i++){
          let modifyfborderrnumber=ordersList[i];

          Object.keys(ordersnew).map((key,index)=>{
            if(ordersnew[key]['order_number']==modifyfborderrnumber){
              let status=ordersnew[key]['status'] || "";
              if(status=="Ordered" || status=="Processed" || status=="Pending"){
                fire.database().ref(`Orders_New/${key}`).update({"status":"Hold"});
              }
            }
          });

        }
      }

      if(ordersList.length>0){
        fetch(`${support_portal_url}/api/updatemysqlbulkorderstatus`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': sessionStorage.getItem("apiToken")
            },
            body: JSON.stringify(details)
        }).then(response => {
           return response.json();
        }).then(data => {
          console.log(data);
          setBackDrop(false)

          if(data["success"]==1){
            return toast(`Successfully updated orders`, { type: toast.TYPE.SUCCESS });
          }else{
            let errorMessage=data["message"]?data["message"]:"";
            toast(`Couldn't hold orders : ${errorMessage}`, { type: toast.TYPE.ERROR });

            if(data['status']==401 || data['status']==403){
              sessionStorage.clear();
              window.location.href="/"
            }else{
              setTimeout(function(){
                 window.location.reload();
              }, 3000);
            }
          }
        }).catch((error) => {
            setBackDrop(false)
            return toast(`Couldn't hold orders : ${error}`, { type: toast.TYPE.ERROR });
        });
      }else{
        setBackDrop(false)
        return toast(`No orders to update`, { type: toast.TYPE.ERROR });
      }

    }).catch((fberror) => {
      alert(fberror)
    })
  }

  const bulkOrdersCancellation = (ordersList) => {
    console.log("bulkOrdersCancellation");
    console.log(ordersList);

    let d = new Date(),
  currdformat = [d.getFullYear(),
            (d.getMonth()+1).padLeft(),
             d.getDate().padLeft()].join('/') +' ' +
            [d.getHours().padLeft(),
             d.getMinutes().padLeft()].join(':');

    let details = {
      'orders': ordersList,
      'updated_at':currdformat,
      'updated_by':JSON.parse(sessionStorage.getItem("user"))[0].email,
      'updated_from':"Disha-OrderList"
    };

    console.log(details);

    let dohcurrDate=new Date();
    let dohdate=dohcurrDate.getDate();
    let dohmonth=dohcurrDate.getMonth()+1;
    let dohyear=dohcurrDate.getFullYear();
    let dohformattedDate = `${dohdate}-${dohmonth}-${dohyear}`;

    let bulkRetailersList = [];
    for(let i=0;i<ordersList.length;i++){
      let orderNum = ordersList[i];
      let retailerID = getRetailerIdFromOrderNumber(orderNum);
      bulkRetailersList.push(retailerID);
    }
    console.log(bulkRetailersList);

    let apiBody = {
      "retailerList" : bulkRetailersList
    }

    Promise.all([
      fire.database().ref(`Driver_Orders_History/${dohyear}/${dohmonth}/${dohformattedDate}`).once("value"),
      fire.database().ref("Orders_New").once("value"),
      fetch(`${support_portal_url}/api/retailer-phone`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'x-access-token': sessionStorage.getItem("apiToken")
          },
          body: JSON.stringify(apiBody)
      }).then(response => response.json()),
      fire.database().ref("Superzop_Kredit_Internal").once('value')
    ]).then(([dohsnapshot,snapshot,apiData,internalcreditsnap]) => {
      let dohorders=dohsnapshot.val() || {};
      console.log(dohorders);
      let plannedOrdersSet=new Set();
      let retailerPhoneMap = apiData['data'] || {};
      let internalCreditData=internalcreditsnap.val() || {};

      console.log("retailerPhoneMap");
      console.log(retailerPhoneMap);

      if(dohsnapshot.val()){
        Object.keys(dohorders).map((rkey,rindex)=>{
          let routeOrders=dohorders[rkey];

          Object.keys(routeOrders).map((dohkey,dohindex)=>{
            let orderNum=routeOrders[dohkey].order_number;
            if(!plannedOrdersSet.has(orderNum)){
              plannedOrdersSet.add(orderNum);
            }
          })
        })
      }

      let mysqlOrdersList=[];

      for(let i=0;i<ordersList.length;i++){
        let orderNum = ordersList[i];

        // current day delivery orders should not be cancelled
        if(!plannedOrdersSet.has(orderNum)){
          mysqlOrdersList.push(orderNum);
        }
      }

      let orderCreditMap = {};
      // orders not planned today
      details['orders']=mysqlOrdersList;

      console.log("plannedOrdersSet");
      console.log(plannedOrdersSet);

      if(mysqlOrdersList.length>0){
        fetch(`${support_portal_url}/api/bulkorderscancel`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': sessionStorage.getItem("apiToken")

            },
            body: JSON.stringify(details),
        }).then(response => {
           return response.json();
        }).then(data => {
          console.log(data);
          setBackDrop(false)

          if(data["success"]==1){
            let previousDaysPlannedOrdersList = data["previousDaysPlannedOrdersList"] || [];
            let ordersnewlist=[];

              if(snapshot.hasChildren()){
                let ordersnew=snapshot.val();
                let driverOrdersObj={};

                for(let i=0;i<ordersList.length;i++){
                  let modifyfborderrnumber=ordersList[i];

                  Object.keys(ordersnew).map((key,index)=>{
                    if(ordersnew[key]['order_number']==modifyfborderrnumber){
                      let status=ordersnew[key]['status']?ordersnew[key]['status']:"";
                      ordersnewlist.push(modifyfborderrnumber);
                      if(!status.includes("Delivered") && !plannedOrdersSet.has(modifyfborderrnumber)){
                        let ordersnewele={};
                        ordersnewele[`/${key}/status`]='Cancelled';

                        let fbOrderNumber=ordersnew[key]['order_number'];
                        fbOrderNumber=fbOrderNumber.replace("-","_");
                        let fbOrderItemId=ordersnew[key]['item_id'];

                        let dohkey = fbOrderNumber.split("/")[0]+"-"+fbOrderNumber.split("/")[1]+"-"+fbOrderItemId;
                        let dohele = JSON.parse(JSON.stringify(ordersnew[key]));
                        dohele['status']='Cancelled';
                        dohele['city']='Bhiwandi';
                        dohele['driver_id']='9998';
                        dohele['vehicle']=9998;
                        dohele['order_date']=formatDate(new Date(dohele['order_date']));
                        let retailerID = dohele['retailer_id'];
                        dohele['phone'] = retailerPhoneMap[retailerID] || "";
                        driverOrdersObj[dohkey]=dohele;

                        fire.database().ref('Orders_New').update(ordersnewele);

                        let currDate=new Date();
                        let date=currDate.getDate();
                        let month=currDate.getMonth()+1;
                        let year=currDate.getFullYear();
                        let formattedDate = `${date}-${month}-${year}`;

                        if(previousDaysPlannedOrdersList.includes(modifyfborderrnumber)) {
                          fire.database().ref(`Driver_Orders_History/${year}/${month}/${formattedDate}/9998/`).update(driverOrdersObj);
                        }

                        let orderCreditAmt = Number(ordersnew[key]['credit_amt']) || 0;
                        if(orderCreditAmt>0) {
                          orderCreditMap[ordersnew[key]['order_number']] = orderCreditAmt;
                        }

                      }
                    }
                  });

                }

                // process superkredit orders - Orders_New
                let kreditObj = {};
                Object.keys(orderCreditMap).map((key,index) => {
                  let kreditRetailer = getRetailerIdFromOrderNumber(key);
                  let creditAmt = orderCreditMap[key];

                  let retailerAvailableCredit=0;
                  if(internalCreditData){
                    if(internalCreditData[kreditRetailer]){
                      if(internalCreditData[kreditRetailer]['approved_details']){
                        retailerAvailableCredit = Number(internalCreditData[kreditRetailer]['approved_details'].available_credit) || 0;
                      }
                    }
                  }

                  let finalRetailerAvailableCredit = retailerAvailableCredit + creditAmt;
                  finalRetailerAvailableCredit = finalRetailerAvailableCredit.toFixed(2);

                  kreditObj[`/${kreditRetailer}/approved_details/available_credit`] = Number(finalRetailerAvailableCredit);
                })

                console.log(kreditObj);

                if(Object.keys(kreditObj).length>0) {
                  fire.database().ref(`Superzop_Kredit_Internal`).update(kreditObj);
                }

                let orders_set = new Set(ordersnewlist);
                let order_new_list = [...orders_set];
                console.log(order_new_list);
                let ordershistorylist=arr_diff(order_new_list,ordersList);
                console.log(ordershistorylist);

                for(let z=0;z<ordershistorylist.length;z++){
                  let fborderhistorynumber=ordershistorylist[z];
                  if(!plannedOrdersSet.has(fborderhistorynumber)){
                    let retaileridd="";
                    if(fborderhistorynumber.includes("-")){
                      retaileridd=fborderhistorynumber.split('-')[0];
                    }else if(fborderhistorynumber.includes("SC")){
                      let retstr=fborderhistorynumber.split('/')[0];
                      retaileridd = retstr.substr(retstr.length - 5);
                    }else{
                      retaileridd=fborderhistorynumber.split('/')[0];
                    }
                    console.log(retaileridd);

                    let firebaseOrderNumberList=fborderhistorynumber.split('/');
                    let firebaseOrderNumber=firebaseOrderNumberList[0]+'-'+firebaseOrderNumberList[1];


                    fire.database().ref(`Orders_History/${retaileridd}/`).once("value", (snapshort) => {
                      if(snapshort.hasChildren()){
                        let finalordershistory=snapshort.val() || {};
                        console.log(finalordershistory);
                        Object.keys(finalordershistory).map((fhkey,fhindex)=>{
                          let ordershistoryele={};
                          let historyDriverOrdersObj={};
                          if(finalordershistory[fhkey]['order_number']==fborderhistorynumber){
                            let status=finalordershistory[fhkey]['status']?finalordershistory[fhkey]['status']:"";

                            if(!status.includes("Delivered")){
                              ordershistoryele[`/${retaileridd}/${fhkey}/status`]='Cancelled';

                              let dohele = JSON.parse(JSON.stringify(finalordershistory[fhkey]));
                              dohele['status']='Cancelled';
                              dohele['city']='Bhiwandi';
                              dohele['driver_id']='9998';
                              dohele['vehicle']=9998;
                              dohele['order_date']=formatDate(new Date(dohele['order_date']));
                              let retailerID = dohele['retailer_id'];
                              dohele['phone'] = retailerPhoneMap[retailerID] || "";
                              historyDriverOrdersObj[fhkey]=dohele;

                              let orrdersHistoryRef=fire.database().ref('Orders_History');
                              orrdersHistoryRef.update(ordershistoryele);

                              let currDate=new Date();
                              let date=currDate.getDate();
                              let month=currDate.getMonth()+1;
                              let year=currDate.getFullYear();
                              let formattedDate = `${date}-${month}-${year}`;

                              if(previousDaysPlannedOrdersList.includes(fborderhistorynumber)) {
                                fire.database().ref(`Driver_Orders_History/${year}/${month}/${formattedDate}/9998/`).update(historyDriverOrdersObj);
                              }

                              let orderCreditAmt = Number(finalordershistory[fhkey]['credit_amt']) || 0;
                              if(orderCreditAmt>0) {
                                // process superkredit order
                                let kreditObj = {};
                                let kreditRetailer = getRetailerIdFromOrderNumber(finalordershistory[fhkey]['order_number']);

                                let retailerAvailableCredit=0;
                                if(internalCreditData){
                                  if(internalCreditData[kreditRetailer]){
                                    if(internalCreditData[kreditRetailer]['approved_details']){
                                      retailerAvailableCredit = Number(internalCreditData[kreditRetailer]['approved_details'].available_credit) || 0;
                                    }
                                  }
                                }

                                let finalRetailerAvailableCredit = retailerAvailableCredit + orderCreditAmt;
                                finalRetailerAvailableCredit = finalRetailerAvailableCredit.toFixed(2);

                                kreditObj[`/${kreditRetailer}/approved_details/available_credit`] = Number(finalRetailerAvailableCredit);

                                console.log(kreditObj);

                                if(Object.keys(kreditObj).length>0) {
                                  fire.database().ref(`Superzop_Kredit_Internal`).update(kreditObj);
                                }
                              }

                            }

                          }
                        });
                      }
                    });
                  }

                }

              }

              // firebase bulk cancel code ends here
              return toast(`Successfully cancelled orders`, { type: toast.TYPE.SUCCESS });
          }else{
            let errorMessage=data["message"]?data["message"]:"";
            toast(`Couldn't cancel orders : ${errorMessage}`, { type: toast.TYPE.ERROR });

            if(data['status']==401 || data['status']==403){
              sessionStorage.clear();
              window.location.href="/"
            }else{
              setTimeout(function(){
                 window.location.reload();
              }, 3000);
            }
          }
        }).catch((error) => {
            setBackDrop(false)
            return toast(`Couldn't cancel orders : ${error}`, { type: toast.TYPE.ERROR });
        });
      }else{
        toast(`All orders are planned for delivery today.`, { type: toast.TYPE.ERROR });
      }

    }).catch((fberror) => {
      alert(fberror)
    })
  }

  const processCancelledSuperkreditOrders = (ordersList) => {
    let apiBody = {
      "orderList" : ordersList
    }

    Promise.all([
      fire.database().ref("Superzop_Kredit_Internal").once('value'),
      fetch(`${support_portal_url}/api/superkredit-order-details`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'x-access-token': sessionStorage.getItem("apiToken")

          },
          body: JSON.stringify(apiBody)
      }).then(response => response.json())
    ]).then(([internalcreditsnap,apiData]) => {
      let internalCreditData=internalcreditsnap.val() || {};
      let apiOrderList = apiData['data'] || [];

      if(apiData['success']==0){
        return toast(`${apiData['message']}`, { type: toast.TYPE.ERROR });
      }

      let fbObj = {};
      let kreditObj = {};
      let bulkOrdersList = [];
      let orderNumberStr = "(";
      let ordernoSet = new Set();
      let historyObj = {};
      for(let apiOrder of apiOrderList) {
        let order_payment_mode = apiOrder.order_payment_mode;
        let order_number = apiOrder.order_number || "";
        let item_id = apiOrder.item_id || "";
        let order_key = order_number.replace("/","-");
        let retailer_id = apiOrder.retailer_id;

        historyObj[`/${retailer_id}/${order_key}-${item_id}/status`] = "Processed";

        if(!ordernoSet.has(order_number)) {
          orderNumberStr += `'${order_number}',`
          bulkOrdersList.push(order_number);
        }


        if(order_payment_mode=="Internal_Credit" && !ordernoSet.has(order_number)) {
          let orderCredit = Number(apiOrder.credit_amt) || 0;
          let user_name = apiOrder.user_name || "";

          let retailerAvailableCredit=0;
          let retailerApprovedKredit=0;
          if(internalCreditData){
            if(internalCreditData[retailer_id]){
              if(internalCreditData[retailer_id]['approved_details']){
                retailerAvailableCredit = Number(internalCreditData[retailer_id]['approved_details'].available_credit) || 0;
              }
            }
          }

          let finalRetailerAvailableCredit = retailerAvailableCredit-orderCredit;
          finalRetailerAvailableCredit = finalRetailerAvailableCredit.toFixed(2);

          fbObj[`/${retailer_id}/approved_details/available_credit`] = Number(finalRetailerAvailableCredit);

          if(user_name) {
            let order_id = order_number.replace("/","-");
            kreditObj[`/${user_name}/${order_id}/status`] = "Processed";
          }
        }

        ordernoSet.add(order_number);
      }

      console.log(fbObj);
      orderNumberStr = orderNumberStr.slice(0,-1);
      orderNumberStr += `)`

      let promisesList = [];
      if(Object.keys(kreditObj).length>0) {
        promisesList.push(fire.database().ref(`Kredit_History`).update(kreditObj))
      }

      if(Object.keys(fbObj).length>0) {
        promisesList.push(fire.database().ref(`Superzop_Kredit_Internal`).update(fbObj))
      }

      if(Object.keys(historyObj).length>0) {
        promisesList.push(fire.database().ref(`Orders_History`).update(historyObj))
      }

      let d = new Date();
      let currdformat = [d.getFullYear(),
              (d.getMonth()+1).padLeft(),
               d.getDate().padLeft()].join('/') +' ' +
              [d.getHours().padLeft(),
               d.getMinutes().padLeft()].join(':');

      let postApiBody = {
        "orders": orderNumberStr,
        "bulkOrdersList": bulkOrdersList,
        "status": "Processed",
        "currentstatus": "Cancelled",
        "currentdateTime": currdformat,
        'updated_by':JSON.parse(sessionStorage.getItem("user"))[0].email,
        'updated_from':"Disha-OrderList"
      }

      promisesList.push(fetch(`${support_portal_url}/api/updatemysqlbulkorderstatus`, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
              'x-access-token': sessionStorage.getItem("apiToken")

          },
          body: JSON.stringify(postApiBody)
      }).then(response => response.json()))

      if(promisesList.length>0) {
        Promise.all(promisesList)
        .then(() => {
          toast(`Processed cancelled superkredit orders.`, { type: toast.TYPE.SUCCESS });
        }).catch((promiseErr) => {
          toast(`${promiseErr}`, { type: toast.TYPE.ERROR });
        })
      }else{
        toast(`Nothing to update`, { type: toast.TYPE.ERROR });
      }

    })
  }

  const syncOrdersNewTable = () => {
    console.log('syncOrdersNewTable');
    fetch("http://services.superzop.com:3000/api/superzop/admin/saveordersnewtablesql")
    .then((response) => {
      console.log(response);
      toast(`Synced Orders New table`, { type: toast.TYPE.SUCCESS });
      setTimeout(function(){
         window.location.reload();
      }, 3000);
    }).catch((error) => {
      return toast("Error in syncing Orders New table : "+error, { type: toast.TYPE.ERROR });
      alert("Error : "+error);
    });
  }

    return (<>
      <NavBar/>

      <div className="container col-sm-12" style={{paddingTop:100}}>
        <center><h2 className="h2">Orders List</h2></center>
        <OrderListForm handleSubmit={handleSubmit} handleBulkOrdersPrintSubmit={handleBulkOrdersPrintSubmit} bulkStatusProcessed={handleBulkStatusProcessed} syncOrdersNewTable={syncOrdersNewTable} updateCheckedForCutoffOrder={updateCheckedForCutoffOrder} ordersList={ordersList} bulkOrdersCancellation={bulkOrdersCancellation} bulkHoldOrders={bulkHoldOrders} processCancelledSuperkreditOrders={processCancelledSuperkreditOrders} ordersListExportHeaders={ordersListExportHeaders}/>
        <Form inline>
        {ordersList &&
        <InputGroup size="sm" >
          <InputGroup.Prepend>
          <InputGroup.Text>Total Order Value</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control  readOnly value={totalOrderValOnCheck!==0?totalOrderValOnCheck:(totalOrderValSearched!==0?totalOrderValSearched:totalOrderValue)}/>
        </InputGroup>}
        <OrdersListSearchForm filterOrders = {setOrdersList} countSearchFilter = {filterSearchedOrders} weightCountSelectedFilter={filterTotalWt} toatlOrderValueSelected = {totalOrderValCalc}
        searchOrders = {searchOrdersList} originalOrdersList = {originalListing}  />
        {ordersList && <InputGroup size="sm">
          <InputGroup.Prepend>
          <InputGroup.Text>Total Order Count</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control style={{width:60}} readOnly value={totalOrderCountOnCheck!==0?totalOrderCountOnCheck:(orderCountFiltered!==0?orderCountFiltered:totalOrderCount)}/>
        </InputGroup>}
        {ordersList && <InputGroup size="sm" style={{paddingLeft:10}}>
          <InputGroup.Prepend>
          <InputGroup.Text>Total Weight </InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control style={{width:80}} readOnly value={totalOrderWtOnCheck?totalOrderWtOnCheck:(weightCountFiltered!==0?weightCountFiltered:totalWeight)}/>
        </InputGroup>}
        </Form>
        {ordersList && <OrderListTable orderListData={ordersList} orderSelectionCounter={setOrderCounter}  searchOrdersListData={searchOrdersList} totalOrderValueOnCheck={setTotalOrderValOnCheck} totalOrderCountOnCheck = {setTotalOrderCountOnCheck} totalOrderWtOnCheck = {setTotalOrderWtOnCheck} isNewRetailerOrder = {isNewRetailerOrder} getCutoffOrderObj={getCutoffOrderObj} />}
        {backDrop?(<Backdrop parentLoadStatus={backDrop}/>):(null)}
        </div></>
    )

}

export default OrderList
