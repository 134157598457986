import React, { useState, useMemo, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NavBar from "../Navigation bar/Navbar";
import axios from "axios";
import { service_url } from "../../Configs/mysqlconfig";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Modal, Form } from "react-bootstrap";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useAsyncDebounce,
} from "react-table";
import { FaPencilAlt } from "react-icons/fa";

let today = new Date();
let dd = String(today.getDate()).padStart(2, "0");
let mm = String(today.getMonth() + 1).padStart(2, "0");
let yyyy = today.getFullYear();
today = yyyy + "-" + mm + "-" + dd;

function Finaleap() {
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [TableDate, setTableData] = useState([]);
  const [StartDate, setStartDate] = useState(today);
  const [EndDate, setEndDate] = useState(today);
  const [Remark, setRemark] = useState();
  const [FinaleapId, setFinaleapId] = useState();
  const [Reload, setReload] = useState(false);
  const [Original, setOriginal] = useState();
  const [Amount, setAmount] = useState();
  const [AmountCredited, setAmountCredited] = useState();

  const handleClose1 = () => setShow1(false);
  const handleShow1 = (original) => {
    // toast("showing tost massage please remove this on  handleShow1 fun");
    setRemark(original.remarks);
    setFinaleapId(original.Finaleap_id);
    setAmount(original?.amount);
    setAmountCredited(original?.amount_credited);
    setOriginal(original);
    setShow1(true);
  };

  const handleClose2 = () => setShow2(false);
  const handleShow2 = (original) => {
    setRemark(original.remarks);
    setFinaleapId(original.Rupifi_id);
    setShow2(true);
  };

  useEffect(() => {
    axios
      .get(
        `${service_url}/api/superzop/finaleap/Finaleap-details?date_start=${StartDate}&date_end=${EndDate}`
      )
      .then((response) => {
        console.log(response.data);
        setTableData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    return () => {};
  }, [StartDate, EndDate, Reload]);

  function updateRemark() {
    let body = {
      Finaleap_id: FinaleapId,
      remarks: Remark,
      amount: Amount,
      amount_credited: AmountCredited,
    };

    axios
      .put(`${service_url}/api/superzop/finaleap/LoadedToFinaleap`, body)
      .then((response) => {
        toast("Remark Update ");
        handleClose1();
        setReload(!Reload);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function LoadedToFinaleap(val, original) {
    console.log(original);
    if (val !== "") {
      let { delivery_date, order_number } = original;
      let body = {
        loaded_to_finaleap: val,
        delivery_date,
        order_number,
      };

      console.log(body);
      axios
        .put(`${service_url}/api/superzop/finaleap/LoadedToFinaleap`, body)
        .then((response) => {
          toast("LoadedToFinaleap Update ");
          setReload(!Reload);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  function Table1() {
    let table = TableDate.map((item) => {
      return {
        delivery_date: item?.delivery_date,
        order_number: item?.order_number,
        warehouse: item?.warehouse,
        asmphone: item?.asmphone,
        status: item?.status,
        total_amt: item?.total_amt,
        shop_name: item?.shop_name,
        driver_id: item?.driver_id,
        loaded_to_finaleap: item?.loaded_to_finaleap,
        amount_credited: item?.amount_credited,
        remarks: item?.remarks,
        Finaleap_id: item?.Finaleap_id,
        amount: item?.amount,
      };
    });

    const data = useMemo(() => table, []);

    const columns = useMemo(
      () => [
        {
          Header: "Delivery date",
          accessor: "delivery_date",
          Filter: SelectColumnFilter,
        },
        {
          Header: "Order number",
          accessor: "order_number",
        },
        {
          Header: "Warehouse",
          accessor: "warehouse",
          Filter: SelectColumnFilter,
        },
        {
          Header: "ASM",
          accessor: "asmphone",
        },
        {
          Header: "Status",
          accessor: "status",
        },
        {
          Header: "Total amt",
          accessor: "total_amt",
        },
        {
          Header: "Shop name",
          accessor: "shop_name",
        },
        {
          Header: "Route",
          accessor: "driver_id",
        },
        {
          Header: "Loaded to Finaleap",
          accessor: " ",
          Cell: ({ row }) => {
            if (row.original.loaded_to_finaleap == 1) {
              return (
                <div>
                  YES <FaPencilAlt onClick={() => handleShow1(row.original)} />
                </div>
              );
            } else if (row.original.loaded_to_finaleap == 0) {
              return <div> NO </div>;
            } else {
              return (
                <div>
                  <select
                    onChange={(e) =>
                      LoadedToFinaleap(e.target.value, row.original)
                    }
                    value={row.original.loaded_to_finaleap}
                  >
                    <option value="">Select</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                </div>
              );
            }
          },
        },
        {
          //
          Header: "Amount credited",
          accessor: "amount_credited",
          Cell: ({ row }) => {
            if (row.original.amount_credited == 0) {
              return <div> NO </div>;
            } else if (row.original.amount_credited == 1) {
              return <div> YES </div>;
            } else {
              return <></>;
            }
          },
        },

        {
          Header: "Amount",
          accessor: "amount",
        },
        {
          Header: "Remarks",
          accessor: "remarks",
        },
      ],
      []
    );

    function SelectColumnFilter({
      column: { filterValue, setFilter, preFilteredRows, id },
    }) {
      const options = useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
          options.add(row.values[id]);
        });
        return [...options.values()];
      }, [id, preFilteredRows]);

      return (
        <select
          value={filterValue}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
        >
          <option value="">All</option>
          {options.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
      );
    }

    const defaultColumn = useMemo(
      () => ({
        Filter: () => null,
      }),
      []
    );

    const GlobalFilter = ({ filter, setFilter }) => {
      const [value, setValue] = useState(filter);
      const onChange = useAsyncDebounce((value) => {
        setFilter(value || undefined);
      }, 1000);
      return (
        <span>
          <Form.Control
            type="text"
            placeholder="Scarch..."
            value={value || ""}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
          />
        </span>
      );
    };

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      setGlobalFilter,
      preGlobalFilteredRows,
    } = useTable({ columns, data, defaultColumn }, useFilters, useGlobalFilter);

    const { globalFilter } = state;

    return (
      <div>
        <Row>
          <Col>
            <Form.Label> Scarch </Form.Label>
            <GlobalFilter
              filter={globalFilter}
              setFilter={setGlobalFilter}
            ></GlobalFilter>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label> Starting </Form.Label>
              <Form.Control
                type="date"
                value={StartDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label> Ending </Form.Label>
              <Form.Control
                type="date"
                value={EndDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Form.Group>
          </Col>
          {/* <Col>
            <Form.Group className="mb-3">
              <Form.Label>
                {" "}
                <p></p>{" "}
              </Form.Label>
              <br />
              <Link to={"/Rupifiapprove"}>
                <Button variant="primary">Rupifi approve</Button>
              </Link>
            </Form.Group>
          </Col> */}
          <Col>{/* <Button variant="success">Primary</Button> */}</Col>
          <Col>{/* <Button variant="warning">Primary</Button>{" "} */}</Col>
          <Col>{/* <Button variant="info">Primary</Button>{" "} */}</Col>
        </Row>
        <br />

        <table
          {...getTableProps()}
          style={{ border: "solid 1px blue", width: "100%" }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      borderBottom: "solid 3px red",
                      background: "aliceblue",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {column.render("Header")}
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "10px",
                          border: "solid 1px gray",
                          background: "papayawhip",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div>
      <NavBar />
      <br />
      <br />
      <br />
      <br />

      <div style={{ margin: 20 }}>
        <h2> Finaleap Management </h2>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <Row>
          <Col>
            <Table1 />
          </Col>
        </Row>

        <Modal show={show1} onHide={handleClose1} size="lg">
          <Modal.Header closeButton>
            <Modal.Title> Amount credited</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Amount credited</Form.Label>
              <br />

              <select
                onChange={(e) => setAmountCredited(e.target.value)}
                value={AmountCredited}
              >
                <option value="">Select</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </Form.Group>

            {AmountCredited && AmountCredited == 1 ? (
              <>
                <Form.Group>
                  <Form.Label>Amount</Form.Label>
                  <Form.Control
                    type="test"
                    value={Amount}
                    onChange={(e) => {
                      setAmount(e.target.value);
                    }}
                  />
                </Form.Group>
              </>
            ) : (
              ""
            )}

            <Form.Group>
              <Form.Label>Remarks</Form.Label>
              <Form.Control
                type="test"
                value={Remark}
                onChange={(e) => {
                  setRemark(e.target.value);
                }}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
            <Button variant="primary" onClick={updateRemark}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default Finaleap;
